import React from 'react';
import { Overlay } from '../SiteLeftSidebar/components/Overlay';
import { SITE_HEADER_MOBILE_HEIGHT, SITE_HEADER_TABLET_HEIGHT, SITE_HEADER_DESKTOP_HEIGHT } from '../constants';
import { PetCaregiverSection } from './components/PetCaregiverSection';
import { Box, BoxProps } from '../../../next-components/box';
import { AppInstallInlineCta } from '../../app-install-banner';
import { Portal } from '../../Portal';
export type SiteTopNavigationProps = BoxProps & {
  isOpen?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryParams?: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  containerProps?: Record<string, any>;
};
export const SiteTopNavigation = ({
  isOpen,
  height = 375,
  queryParams = {},
  ...rest
}: SiteTopNavigationProps) => {
  if (typeof window === 'undefined') {
    return null;
  }
  return <Portal data-sentry-element="Portal" data-sentry-component="SiteTopNavigation" data-sentry-source-file="SiteTopNavigation.tsx">
      <Overlay visible={isOpen} data-sentry-element="Overlay" data-sentry-source-file="SiteTopNavigation.tsx">
        {/* The condition for the height is for the slide-down animation */}
        <Box height={isOpen ? height : 0} overflow="hidden" width="100%" backgroundColor="white" paddingTop={[SITE_HEADER_MOBILE_HEIGHT + 16, SITE_HEADER_TABLET_HEIGHT + 16, SITE_HEADER_DESKTOP_HEIGHT + 16]} pb="16px" px="16px" transition="height 0.2s ease-out" {...rest} data-sentry-element="Box" data-sentry-source-file="SiteTopNavigation.tsx">
          <AppInstallInlineCta mt="20px" mb={4} primaryText="Wag! for Pet Parents" data-sentry-element="AppInstallInlineCta" data-sentry-source-file="SiteTopNavigation.tsx" />
          <PetCaregiverSection queryParams={queryParams} data-sentry-element="PetCaregiverSection" data-sentry-source-file="SiteTopNavigation.tsx" />
        </Box>
      </Overlay>
    </Portal>;
};