// @flow
import styled from 'styled-components';
import { background, border, color, compose, flexbox, grid, layout, position, shadow, space, typography } from 'styled-system';
const styleProps = compose(background, border, color, flexbox, grid, layout, position, shadow, space, typography);
export const Strong = styled.strong<typeof styleProps & {
  fontFamily?: string;
  color?: string;
  children?: React.ReactNode;
}>`
  ${styleProps}
`;