import React from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import { URL_GET_THE_APP, URL_BECOME_WALKER, URL_DOG_SITTER } from '../../urls';
import { QUERY_STRING_KEY, UTM_SOURCE, UTM_CAMPAIGN } from '../../constants';
import petCaregiverIllustration from '../../assets/pet-caregiver-illustration.svg';
import { ChevronRightIcon } from '../../../../next-components/icons';
import { Box } from '../../../../next-components/box';
import { Button } from '../../../../next-components/button';
import { Flex } from '../../../../next-components/Flex';
import { Grid } from '../../../../core-components/grid';
import { Image } from '../../../../core-components/image';
import { Typography } from '../../../Typography';
export type PetCaregiverSectionProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryParams?: Record<string, any>;
};
const StyledGrid = styled(Grid).attrs(() => ({
  gridTemplateColumns: ['100px 216px', '100px 1fr']
}))``;
const StyledBox = styled(Box).attrs(() => ({
  ml: '24px'
}))``;
const StyledTypography = styled(Typography).attrs(() => ({
  fontSize: '24px',
  fontFamily: 'muliBold',
  color: '#3A5080',
  m: 0
}))``;
export const PetCaregiverSection = ({
  queryParams,
  ...rest
}: PetCaregiverSectionProps) => {
  const becomeAWalkerUrl = queryString.stringifyUrl({
    url: URL_BECOME_WALKER,
    query: {
      [QUERY_STRING_KEY.UTM_CAMPAIGN]: UTM_CAMPAIGN.NAVIGATION_MOBILE_BECOME_A_PET_CAREGIVER,
      [QUERY_STRING_KEY.UTM_SOURCE]: UTM_SOURCE.WEB,
      ...queryParams
    }
  });
  const becomeASitterUrl = queryString.stringifyUrl({
    url: URL_DOG_SITTER,
    query: {
      [QUERY_STRING_KEY.UTM_CAMPAIGN]: UTM_CAMPAIGN.NAVIGATION_MOBILE_BECOME_A_PET_CAREGIVER,
      [QUERY_STRING_KEY.UTM_SOURCE]: UTM_SOURCE.WEB,
      ...queryParams
    }
  });
  const getTheAppUrl = URL_GET_THE_APP;
  return <StyledGrid {...rest} data-sentry-element="StyledGrid" data-sentry-component="PetCaregiverSection" data-sentry-source-file="PetCaregiverSection.tsx">
      <Image height="84px" width="104px" src={petCaregiverIllustration} alt="pet-parent-illustration" data-sentry-element="Image" data-sentry-source-file="PetCaregiverSection.tsx" />
      <StyledBox data-sentry-element="StyledBox" data-sentry-source-file="PetCaregiverSection.tsx">
        <StyledTypography data-sentry-element="StyledTypography" data-sentry-source-file="PetCaregiverSection.tsx">
          Pet Caregiver
        </StyledTypography>
        <Typography my="4px" fontSize="14px" data-sentry-element="Typography" data-sentry-source-file="PetCaregiverSection.tsx">
          Find pet care jobs on Wag!
        </Typography>

        <Flex flexDirection="column" alignItems="start" data-sentry-element="Flex" data-sentry-source-file="PetCaregiverSection.tsx">
          <Button fontFamily="muliBold" fontSize="16px" href={becomeAWalkerUrl} data-sentry-element="Button" data-sentry-source-file="PetCaregiverSection.tsx">
            Become a Walker
            <ChevronRightIcon color="wagGreen" data-sentry-element="ChevronRightIcon" data-sentry-source-file="PetCaregiverSection.tsx" />
          </Button>
          <Button fontFamily="muliBold" fontSize="16px" href={becomeASitterUrl} data-sentry-element="Button" data-sentry-source-file="PetCaregiverSection.tsx">
            Become a Sitter
            <ChevronRightIcon color="wagGreen" data-sentry-element="ChevronRightIcon" data-sentry-source-file="PetCaregiverSection.tsx" />
          </Button>
        </Flex>
        
        <Typography fontSize="16px" color="#727272" mt="24px" mb={0} data-sentry-element="Typography" data-sentry-source-file="PetCaregiverSection.tsx">
          Approved Caregiver?
        </Typography>
        <Button href={getTheAppUrl} fontFamily="muliBold" fontSize="16px" data-sentry-element="Button" data-sentry-source-file="PetCaregiverSection.tsx">
          Get the app
        </Button>
      </StyledBox>
    </StyledGrid>;
};