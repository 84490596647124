import React from 'react';
import { AppLogoIcon } from './icons/AppLogoIcon';
import { AppInstallUrls } from './constants';
import { Button } from '../../next-components/button';
import { Flex, FlexProps } from '../../next-components/Flex';
import { Typography } from '../Typography';
import { Rating } from '../Rating';
export type AppInstallInlineCtaProps = {
  primaryText?: string;
  secondaryText?: string;
  ctaText?: string;
  ctaUrl?: string;
  variant?: 'border' | 'boxShadow';
} & FlexProps;
export enum Variants {
  Outlined = 'border',
  Elevated = 'boxShadow',
}
const variantStyles = {
  [Variants.Outlined]: '1px solid lightGrey',
  [Variants.Elevated]: '0 0 10px rgba(0, 0, 0, 0.23)'
};
export const AppInstallInlineCta = ({
  primaryText = 'Wag! App',
  secondaryText = '43k+ reviews',
  ctaText = 'Install',
  ctaUrl = AppInstallUrls.Branch,
  variant = Variants.Outlined,
  ...rest
}: AppInstallInlineCtaProps) => {
  const variantProps = {};
  if (variant === Variants.Outlined) {
    variantProps[Variants.Outlined] = variantStyles[variant];
  } else {
    variantProps[Variants.Elevated] = variantStyles[variant];
  }
  return <Button href={ctaUrl} bg="white" display="block" innerProps={{
    display: 'block'
  }} data-sentry-element="Button" data-sentry-component="AppInstallInlineCta" data-sentry-source-file="AppInstallInlineCta.tsx">
      <Flex borderRadius="12px" justifyContent="space-between" height="75px" p="8px" {...variantProps} {...rest} data-sentry-element="Flex" data-sentry-source-file="AppInstallInlineCta.tsx">
        <AppLogoIcon borderRadius="8px" overflow="hidden" data-sentry-element="AppLogoIcon" data-sentry-source-file="AppInstallInlineCta.tsx" />
        <Flex flex={1} flexDirection="column" justifyContent="space-between" ml="12px" data-sentry-element="Flex" data-sentry-source-file="AppInstallInlineCta.tsx">
          <Typography color="#474747" cursor="pointer" fontFamily="muliBold" fontSize="14px" m={0} data-sentry-element="Typography" data-sentry-source-file="AppInstallInlineCta.tsx">
            {primaryText}
          </Typography>

          <Rating value={5} data-sentry-element="Rating" data-sentry-source-file="AppInstallInlineCta.tsx" />
          <Typography cursor="pointer" fontSize="14px" m={0} data-sentry-element="Typography" data-sentry-source-file="AppInstallInlineCta.tsx">
            {secondaryText}
          </Typography>
        </Flex>
        {ctaText && <Flex flexDirection="column" justifyContent="center">
            <Typography color="wagGreen" cursor="pointer" fontFamily="muliBold" fontSize="14px" textTransform="uppercase">
              {ctaText}
            </Typography>
          </Flex>}
      </Flex>
    </Button>;
};