'use client';

import React, { useState } from 'react';
import { CopyrightText } from '../../CopyrightText';
import { DownloadAppText } from '../../DownloadAppText';
import { FooterLink } from '../../FooterLink';
import { SocialIconLinks } from '../../SocialIconLinks';
import { AppStoreLink } from '../../AppStoreLink';
import { GooglePlayLink } from '../../GooglePlayLink';
import { Link } from '../../types/types';
import { FooterHelpCenterLink } from './FooterHelpCenterLink';
import { FooterLinksGroup } from './FooterLinksGroup';
import { Flex } from '../../../../next-components/Flex';
import { WagLogoIcon } from '../../../../next-components/icons';
import { Box } from '../../../../core-components/box';
import { Divider } from '../../../../core-components/divider';
import { Grid } from '../../../../core-components/grid';
import { ConsentConfirmation } from '../../ConsentConfirmation/ConsentConfirmation';
import { Modal } from '../../../seo-models';
import { ConsentModal } from '../../ConsentModal';
type Props = {
  data: {
    aboutWagLinks: Link[];
    legalLinks: Link[];
    lifestyleLinks: Link[];
    resourcesLinks: Link[];
    securityLinks: Link[];
    topWagCitiesLinks: Link[];
  };
  display: string[];
};
export const SiteFooterTablet = (props: Props) => {
  const {
    data,
    ...rest
  } = props;
  const [consentModalIsOpen, setConsentModalIsOpen] = useState(false);
  const {
    aboutWagLinks,
    legalLinks,
    lifestyleLinks,
    resourcesLinks,
    securityLinks,
    topWagCitiesLinks
  } = data;
  return <Box bg="backgroundGray" boxSizing="border-box" pb="32px" pt="56px" px="32px" width={1} {...rest} data-sentry-element="Box" data-sentry-component="SiteFooterTablet" data-sentry-source-file="SiteFooterTablet.tsx">
      <Flex data-sentry-element="Flex" data-sentry-source-file="SiteFooterTablet.tsx">
        <Flex flex={4} flexDirection="column" data-sentry-element="Flex" data-sentry-source-file="SiteFooterTablet.tsx">
          <WagLogoIcon mb="24px" name="wagTextLogo" alt="wagTextLogo" width="112px" height="52px" data-sentry-element="WagLogoIcon" data-sentry-source-file="SiteFooterTablet.tsx" />
          <FooterHelpCenterLink mb="40px" data-sentry-element="FooterHelpCenterLink" data-sentry-source-file="SiteFooterTablet.tsx" />
          <DownloadAppText mb="96px" data-sentry-element="DownloadAppText" data-sentry-source-file="SiteFooterTablet.tsx" />
          <AppStoreLink mb="16px" data-sentry-element="AppStoreLink" data-sentry-source-file="SiteFooterTablet.tsx" />
          <GooglePlayLink mb="32px" data-sentry-element="GooglePlayLink" data-sentry-source-file="SiteFooterTablet.tsx" />
          <SocialIconLinks data-sentry-element="SocialIconLinks" data-sentry-source-file="SiteFooterTablet.tsx" />
        </Flex>
        <Flex flex={2} flexDirection="column" data-sentry-element="Flex" data-sentry-source-file="SiteFooterTablet.tsx">
          <FooterLinksGroup data={{
          links: aboutWagLinks
        }} mb="32px" title="About Wag!" data-sentry-element="FooterLinksGroup" data-sentry-source-file="SiteFooterTablet.tsx" />
          <FooterLinksGroup data={{
          links: resourcesLinks
        }} title="Resources" data-sentry-element="FooterLinksGroup" data-sentry-source-file="SiteFooterTablet.tsx" />
        </Flex>
        <Flex flexDirection="column" flex={2} data-sentry-element="Flex" data-sentry-source-file="SiteFooterTablet.tsx">
          <FooterLinksGroup data={{
          links: topWagCitiesLinks
        }} mb="32px" title="Top Wag! cities" data-sentry-element="FooterLinksGroup" data-sentry-source-file="SiteFooterTablet.tsx" />
          <FooterLinksGroup data={{
          links: lifestyleLinks
        }} mb="32px" title="Lifestyles" data-sentry-element="FooterLinksGroup" data-sentry-source-file="SiteFooterTablet.tsx" />
          <FooterLinksGroup data={{
          links: securityLinks
        }} title="Security" data-sentry-element="FooterLinksGroup" data-sentry-source-file="SiteFooterTablet.tsx" />
        </Flex>
      </Flex>
      <Divider mb="32px" mt="40px" data-sentry-element="Divider" data-sentry-source-file="SiteFooterTablet.tsx" />
      <Flex mb="80px" data-sentry-element="Flex" data-sentry-source-file="SiteFooterTablet.tsx">
        <Grid gridTemplateColumns="repeat(3, 1fr)" flex={2} data-sentry-element="Grid" data-sentry-source-file="SiteFooterTablet.tsx">
          {legalLinks.map(({
          url,
          label
        }) => <FooterLink key={label} href={url}>
              {label}
            </FooterLink>)}
          <ConsentConfirmation setConsentModalIsOpen={setConsentModalIsOpen} data-sentry-element="ConsentConfirmation" data-sentry-source-file="SiteFooterTablet.tsx" />

          {consentModalIsOpen && <ConsentModal setConsentModalIsOpen={setConsentModalIsOpen} consentModalIsOpen={consentModalIsOpen} />}
        </Grid>
      </Flex>
      <CopyrightText textAlign='left' flex={1} data-sentry-element="CopyrightText" data-sentry-source-file="SiteFooterTablet.tsx" />
      {consentModalIsOpen && <Modal>
          <Box>
            Consent Modal
          </Box>
        </Modal>}
    </Box>;
};