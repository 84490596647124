'use client';

import { useState } from 'react';

type ToggleValue = boolean;

export type Props = {
  initialValue?: ToggleValue;
  toggleFunction?: (value: ToggleValue) => boolean;
};

export const useToggle = ({
  initialValue = false,
  toggleFunction,
}: Props = {}) => {
  const [on, setOn] = useState<ToggleValue>(initialValue);

  // eslint-disable-next-line consistent-return
  const handleToggle = (value?: ToggleValue) => {
    const hasValue = typeof value === 'boolean';
    if (toggleFunction) {
      return setOn(toggleFunction(hasValue ? value : on));
    }
    setOn(hasValue ? value : !on);
  };

  const reset = () => {
    setOn(initialValue);
  };

  return {
    on,
    reset,
    toggle: handleToggle,
  };
};
