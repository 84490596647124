import React, { ReactNode } from 'react';
import { BoxProps } from '../../../../next-components/box';
import { Flex } from '../../../../next-components/Flex';
type Props = {
  flexDirection?: 'column' | 'row';
  children?: ReactNode;
} & BoxProps;
export const List = ({
  children = null,
  flexDirection = 'column',
  ...rest
}: Props) => <Flex flexDirection={flexDirection} {...rest} data-sentry-element="Flex" data-sentry-component="List" data-sentry-source-file="List.tsx">
    {children}
  </Flex>;