import React, { ReactNode } from 'react';
import { Typography, TypographyProps } from '../../../Typography';
type Props = {
  children?: ReactNode;
} & TypographyProps;
export const ListItemText = ({
  children = null,
  ...rest
}: Props) => <Typography variant="body2" color="inherit" fontSize="inherit" fontFamily="inherit" fontWeight="inherit" m={0} hover {...rest} data-sentry-element="Typography" data-sentry-component="ListItemText" data-sentry-source-file="ListItemText.tsx">
    {children}
  </Typography>;