import React, { useContext, ReactNode } from 'react';
import { AccordionItemContext } from '../AccordionItemContext';
import { PlusIcon, MinusIcon } from '../../../../next-components/icons';
import { Flex } from '../../../../next-components/Flex';
import { Box } from '../../../../next-components/box';
import { Typography } from '../../../Typography';
export type AccordionHeaderProps = {
  expandIcon?: ReactNode;
  contractIcon?: ReactNode;
  children: ReactNode;
};
export const AccordionHeader = ({
  expandIcon = <PlusIcon color="wagGreen" height="18px" width="18px" />,
  contractIcon = <MinusIcon color="wagGreen" height="18px" width="18px" />,
  children
}: AccordionHeaderProps) => {
  const {
    isExpanded,
    toggle
  } = useContext(AccordionItemContext);
  return <Flex alignItems="center" cursor="pointer" justifyContent="space-between" onClick={toggle} pr={['8px', 0]} data-sentry-element="Flex" data-sentry-component="AccordionHeader" data-sentry-source-file="index.tsx">
      <Box cursor="inherit" data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <Typography hover fontWeight="bold" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
          {children}
        </Typography>
      </Box>
      <Box cursor="inherit" data-sentry-element="Box" data-sentry-source-file="index.tsx">
        {isExpanded ? contractIcon : expandIcon}
      </Box>
    </Flex>;
};