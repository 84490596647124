import React from 'react';
import { aboutWagLinksData, legalLinksData, lifestyleLinksData, resourcesLinksData, securityLinksData, topWagCitiesLinksData } from '../urls';
import { helper } from '../utils';
import { SiteFooterDesktop } from './components/SiteFooterDesktop';
import { SiteFooterMobile } from './components/SiteFooterMobile';
import { SiteFooterTablet } from './components/SiteFooterTablet';
export type Props = Record<string, unknown>;
export const SiteFooter = (props: Props) => {
  const aboutWagLinks = helper.attachQueryStringToRoutes(aboutWagLinksData);
  const legalLinks = helper.attachQueryStringToRoutes(legalLinksData);
  const lifestyleLinks = helper.attachQueryStringToRoutes(lifestyleLinksData);
  const resourcesLinks = helper.attachQueryStringToRoutes(resourcesLinksData);
  const securityLinks = helper.attachQueryStringToRoutes(securityLinksData);
  const topWagCitiesLinks = helper.attachQueryStringToRoutes(topWagCitiesLinksData);
  return <>
      <SiteFooterMobile {...props} data={{
      aboutWagLinks,
      legalLinks,
      lifestyleLinks,
      resourcesLinks,
      securityLinks,
      topWagCitiesLinks
    }} display={['block', 'none']} data-sentry-element="SiteFooterMobile" data-sentry-source-file="SiteFooter.tsx" />
      <SiteFooterTablet {...props} data={{
      aboutWagLinks,
      legalLinks,
      lifestyleLinks,
      resourcesLinks,
      securityLinks,
      topWagCitiesLinks
    }} display={['none', 'block', 'none']} data-sentry-element="SiteFooterTablet" data-sentry-source-file="SiteFooter.tsx" />
      <SiteFooterDesktop {...props} data={{
      aboutWagLinks,
      legalLinks,
      lifestyleLinks,
      resourcesLinks,
      securityLinks,
      topWagCitiesLinks
    }} display={['none', 'none', 'block']} data-sentry-element="SiteFooterDesktop" data-sentry-source-file="SiteFooter.tsx" />
    </>;
};