import React from 'react';
import { SITE_HEADER_DESKTOP_HEIGHT } from '../constants';
import { SvgIconProps } from '../../../next-components/icons';
import { Link } from '../../../core-components/link';
import { Flex, FlexProps } from '../../../next-components/Flex';
import { Typography, TypographyProps } from '../../Typography';
type Items = {
  key: string;
  url: string;
  containerProps?: FlexProps;
  label: string;
  labelProps?: TypographyProps;
  description?: string;
  descriptionProps?: TypographyProps;
  IconComponent: typeof React.Component;
  iconProps?: SvgIconProps;
};
export type NavigationDropdownBannerProps = {
  isActive: boolean;
  items: Items[];
};
export const NavigationDropdownBanner = ({
  isActive,
  items,
  ...rest
}: NavigationDropdownBannerProps) => <Flex bg="white" borderTop="3px solid" borderBottom="3px solid" borderColor="backgroundGray" display={isActive ? 'flex' : 'none'} justifyContent="center" left={0} position="absolute" py={1} top={SITE_HEADER_DESKTOP_HEIGHT} width="100%" {...rest} data-sentry-element="Flex" data-sentry-component="NavigationDropdownBanner" data-sentry-source-file="NavigationDropdownBanner.tsx">
    {items.map(({
    key,
    url,
    containerProps,
    label,
    labelProps,
    description,
    descriptionProps,
    IconComponent,
    iconProps
  }) => <Link key={key} href={url}>
        <Flex alignItems="center" borderRadius={3} flexDirection="column" p={2} mx={5} {...containerProps}>
          <IconComponent height="64px" width="64px" {...iconProps} />

          <Typography variant="body2" color="charcoal" fontFamily="muliSemiBold" fontSize="18px" {...labelProps}>
            {label}
          </Typography>

          {description && <Typography variant="body2" fontSize="14px" {...descriptionProps}>
              {description}
            </Typography>}
        </Flex>
      </Link>)}
  </Flex>;