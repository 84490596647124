import React, { useEffect, useState } from 'react';
import { Box } from '../../../../core-components/box';
import { BoxProps } from '../../../../next-components/box';
type Props = {
  visible: boolean;
} & BoxProps;
export const Overlay = ({
  visible,
  ...rest
}: Props) => {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);
  if (!isClient) {
    return null;
  }
  return <Box bg="rgba(0, 0, 0, 0.2)" bottom={0} left={0} opacity={visible ? '1' : '0'} position="fixed" right={0} top={0} transition="all .25s ease-in-out" visibility={visible ? 'visible' : 'hidden'} {...rest} data-sentry-element="Box" data-sentry-component="Overlay" data-sentry-source-file="Overlay.tsx" />;
};