import React, { ReactNode } from 'react';
import { Link } from '../../../core-components/link';
import { BoxProps } from '../../../next-components/box';
type Props = {
  /**
   * ? TODO: Is this the right type definition?
   * Why does it evaluate to 'any'?
   */
  children: ReactNode;
  href: string;
} & BoxProps;
export const FooterLink = (props: Props) => {
  const {
    children,
    href,
    ...rest
  } = props;
  return <Link color="#999999" fontFamily="muli" fontSize="0.875rem" mb={1} href={href} {...rest} data-sentry-element="Link" data-sentry-component="FooterLink" data-sentry-source-file="FooterLink.tsx">
      {children}
    </Link>;
};