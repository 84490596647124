'use client';

import React, { useContext, useEffect, ReactNode, CSSProperties } from 'react';
import { ThemeContext, createGlobalStyle } from 'styled-components';
import ReactModal, { Props as ReactModalProps } from 'react-modal';
import { useMediaQuery } from 'react-responsive';
import { ModalCloseIconButton, ModalCloseIconButtonProps } from './ModalCloseIconButton';
import { useToggle } from '../../../common/hooks/useToggle';
import { Flex } from '../../../next-components/Flex';
export const DEFAULT_MODAL_AUTOSHOW_TIME_MS = 10000;
export type ModalProps = {
  children: ReactNode;
  autoShow?: 'once';
  autoShowTimeMs?: number;
  closeIconButtonProps?: ModalCloseIconButtonProps;
  noDefaultCloseIcon?: boolean;
  customCloseIconButton?: ({
    onRequestClose
  }: ReactModalProps) => JSX.Element;
  onOpen?: () => void;
  contentStyle?: CSSProperties;
} & Omit<ReactModalProps, 'isOpen'> & Partial<Pick<ReactModalProps, 'isOpen'>>;
export const Modal = ({
  autoShow,
  autoShowTimeMs = DEFAULT_MODAL_AUTOSHOW_TIME_MS,
  children,
  closeIconButtonProps,
  isOpen: isOpenProp,
  noDefaultCloseIcon,
  customCloseIconButton,
  contentStyle,
  onOpen,
  onRequestClose: onRequestCloseProp,
  ...rest
}: ModalProps) => {
  const {
    on: isOpenState,
    toggle: toggleIsOpenState
  } = useToggle();
  const isOpenPropPassed = typeof isOpenProp !== 'undefined';
  const isOpen = isOpenPropPassed ? isOpenProp : isOpenState;
  const onRequestClose = isOpenPropPassed ? onRequestCloseProp : () => toggleIsOpenState(false);
  useEffect(() => {
    if (!isOpen) {
      return;
    }
    if (!onOpen) {
      return;
    }
    onOpen();
  }, [isOpen, onOpen]);
  useEffect(() => {
    if (!autoShow) {
      return;
    }
    if (isOpenPropPassed) {
      return;
    }
    const timeout = setTimeout(() => {
      toggleIsOpenState(true);
    }, autoShowTimeMs);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timeout);
    };
  }, [autoShow, autoShowTimeMs, isOpenPropPassed, toggleIsOpenState]);
  const theme = useContext(ThemeContext);
  const isMobile = useMediaQuery({
    maxWidth: theme.breakpoints[0]
  });
  const CustomCloseIcon = customCloseIconButton;
  return <>
    <ReactModal closeTimeoutMS={250} isOpen={isOpen} style={{
      content: {
        border: 'none',
        borderRadius: '16px',
        boxSizing: 'border-box',
        left: '16px',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxHeight: 'max-content',
        maxWidth: '480px',
        padding: 0,
        position: isMobile ? 'absolute' : 'relative',
        right: '16px',
        top: '2%',
        ...(isMobile && {
          bottom: 'unset'
        }),
        ...contentStyle
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1300
      }
    }} onRequestClose={onRequestClose} {...rest} data-sentry-element="ReactModal" data-sentry-source-file="Modal.tsx">
      {!noDefaultCloseIcon && <ModalCloseIconButton onClick={onRequestClose} {...closeIconButtonProps} />}
      {customCloseIconButton && <Flex justifyContent="end" zIndex="11" position="absolute" top="4px" right="4px">
          <CustomCloseIcon onRequestClose={onRequestClose} />
        </Flex>}
      {children}
    </ReactModal>
    <GlobalStyle data-sentry-element="GlobalStyle" data-sentry-source-file="Modal.tsx" />
  </>;
};
const GlobalStyle = createGlobalStyle`
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 250ms ease-in-out;
  }

  .ReactModal__Overlay--after-open{
    opacity: 1;
  }

  .ReactModal__Overlay--before-close{
    opacity: 0;
  }
`;