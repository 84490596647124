import React from 'react';
import { FacebookLogoIcon } from '../FacebookLogoIcon';
import { InstagramLogoIcon } from '../InstagramLogoIcon';
import { TwitterLogoIcon } from '../TwitterLogoIcon';
import { TiktokLogoIcon } from '../TiktokLogoIcon';
import { Link } from '../../../core-components/link';
import { Grid, GridProps } from '../../../core-components/grid';
const socialIcons = [{
  name: 'facebook',
  url: 'https://facebook.com/wagwalking',
  iconComponent: FacebookLogoIcon
}, {
  name: 'instagram',
  url: 'https://instagram.com/wag',
  iconComponent: InstagramLogoIcon
}, {
  name: 'twitter',
  url: 'https://twitter.com/wagwalking',
  iconComponent: TwitterLogoIcon
}, {
  name: 'tiktok',
  url: 'https://www.tiktok.com/@wag',
  iconComponent: TiktokLogoIcon
}];
type Props = GridProps;
export const SocialIconLinks = (props: Props) => <Grid gridTemplateColumns={['repeat(auto-fit, 40px)', 'repeat(4, 40px)']} gridGap="4px" {...props} data-sentry-element="Grid" data-sentry-component="SocialIconLinks" data-sentry-source-file="SocialIconLinks.tsx">
    {socialIcons.map(({
    url,
    name,
    iconComponent: IconComponent
  }) => <Link key={name} href={url} target="_blank" aria-label={`${name}-icon-link`}>
        <IconComponent fontSize="1.875rem" />
      </Link>)}
  </Grid>;