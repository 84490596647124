import React, { ReactNode, ReactEventHandler, CSSProperties } from 'react';
import { StyledSystemProps } from '../../types';
import { H1 } from '../../wag-react/core-components/h1';
import { H2 } from '../../wag-react/core-components/h2';
import { H3 } from '../../wag-react/core-components/h3';
import { Box } from '../../wag-react/next-components/box';
import { Text } from '../../wag-react/core-components/text';
import { Span } from '../../wag-react/core-components/span';

// Place the default props here specific to each variant
const variantMapping = {
  h1: (props: StyledSystemProps) => <H1 {...props} />,
  h2: (props: StyledSystemProps) => <H2 {...props} />,
  h3: (props: StyledSystemProps) => <H3 {...props} />,
  h4: (props: StyledSystemProps) => <Box as="h4" {...props} />,
  h5: (props: StyledSystemProps) => <Box as="h5" {...props} />,
  body1: (props: StyledSystemProps) => <Text {...props} />,
  body2: (props: StyledSystemProps) => <Span {...props} />,
  legal: (props: StyledSystemProps) => <Text {...props} />
};
type VariantMapping = typeof variantMapping;
type Variants = keyof VariantMapping;
export type Props = {
  children: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  hover?: boolean;
  variant?: Variants;
  style?: CSSProperties;
  onClick?: ReactEventHandler<HTMLElement>;
  /**
   * To avoid regression error on the design,
   * add the `__experimentalApplyDefaultFontFamily`
   * to apply the experimental default font family
   */
  __experimentalApplyDefaultFontFamily?: boolean;
} & StyledSystemProps;
const defaults = {
  fontSize: (semantic: Variants) => ({
    fontSize: [`mobile-${semantic}`, `tablet-${semantic}`, `desktop-${semantic}`]
  }),
  fontFamily: (semantic: Variants) => ({
    fontFamily: ['h1', 'h2', 'h3', 'h4', 'h5'].includes(semantic) ? 'muliBold' : 'muli'
  })
};
export type TypographyProps = Props;
export const Typography = ({
  color = 'medGray',
  fontFamily = 'muli',
  variant = 'body1',
  component,
  __experimentalApplyDefaultFontFamily = false,
  ...rest
}: Props) => {
  const Component = component || variantMapping[variant];
  return <Component color={color} fontFamily={fontFamily} {...defaults.fontSize(variant)} {...__experimentalApplyDefaultFontFamily && defaults.fontFamily(variant)} {...rest} as="div" data-sentry-element="Component" data-sentry-component="Typography" data-sentry-source-file="index.tsx" />;
};