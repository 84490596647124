import React, { ReactNode } from 'react';
import { Box, BoxProps } from '../../next-components/box';
import { AccordionBody } from './components/AccordionBody';
import { AccordionHeader } from './components/AccordionHeader';
import { AccordionItem } from './components/AccordionItem';
export type AccordionProps = {
  items: Array<Record<string, string | string[]>>;
  contractIcon?: ReactNode;
  expandIcon?: ReactNode;
} & BoxProps;
export const Accordion = ({
  items,
  contractIcon,
  expandIcon,
  ...rest
}: AccordionProps) => <Box {...rest} data-sentry-element="Box" data-sentry-component="Accordion" data-sentry-source-file="Accordion.tsx">
    {items.map((item, index) => <AccordionItem key={`${item.header.toString()}-${String(index)}`}>
        <AccordionHeader expandIcon={item.body ? expandIcon : null} contractIcon={item.body ? contractIcon : null}>
          {item.header}
        </AccordionHeader>

        {item.body && <AccordionBody item={item} />}
      </AccordionItem>)}
  </Box>;