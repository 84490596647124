import React from 'react';
import { appStoreLinkData } from '../urls';
import { helper } from '../utils';
import { AppStoreIcon } from '../../../next-components/icons';
import { Link } from '../../../core-components/link';
import { BoxProps } from '../../../next-components/box';
export type AppStoreLinkProps = BoxProps;
export const AppStoreLink = (props: AppStoreLinkProps) => {
  const {
    url: appStoreLinkUrl
  } = helper.attachQueryStringToRoute(appStoreLinkData);
  return <Link href={appStoreLinkUrl} target="_blank" {...props} data-sentry-element="Link" data-sentry-component="AppStoreLink" data-sentry-source-file="AppStoreLink.tsx">
      <AppStoreIcon cursor="pointer" name="appStore" alt="app-store-logo" width={['144px', '152px']} height="46px" data-sentry-element="AppStoreIcon" data-sentry-source-file="AppStoreLink.tsx" />
    </Link>;
};