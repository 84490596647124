'use client';

import React, { useEffect, useState } from 'react';
import { SiteHeader, SiteHeaderProps } from '../SiteHeader';
import { SiteLeftSidebar, SiteLeftSidebarProps } from '../SiteLeftSidebar';
import { SiteTopNavigation, SiteTopNavigationProps } from '../SiteTopNavigation';
import { NavigationContext } from '../NavigationContext';
import { Box } from '../../../next-components/box';
type Props = {
  // Partials allow for empty object instantiation of the prop
  leftSidebarProps?: Partial<SiteLeftSidebarProps>;
  headerProps?: Partial<SiteHeaderProps>;
  topNavigationProps?: Partial<SiteTopNavigationProps>;
};
const BREAKPOINT_DESKTOP = 832;
export const SiteNavigation = ({
  leftSidebarProps = {},
  headerProps = {},
  topNavigationProps = {}
}: Props) => {
  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(false);
  const [isTopNavigationOpen, setIsTopNavigationOpen] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const toggleIsLeftSidebarOpen = () => {
    setIsLeftSidebarOpen(!isLeftSidebarOpen);
    if (isTopNavigationOpen) {
      toggleTopNavigation();
    }
  };
  const toggleTopNavigation = () => {
    setIsTopNavigationOpen(!isTopNavigationOpen);
  };
  const resizeListener = () => {
    if (window.innerWidth > BREAKPOINT_DESKTOP) {
      setIsLeftSidebarOpen(false);
      setIsTopNavigationOpen(false);
    }
  };
  useEffect(() => {
    setIsClient(true);
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', resizeListener);
    }
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);
  if (!isClient) {
    return null;
  }
  return <Box data-sentry-element="Box" data-sentry-component="SiteNavigation" data-sentry-source-file="SiteNavigation.tsx">
      <NavigationContext.Provider value={{
      isTopNavigationOpen,
      isLeftSidebarOpen,
      toggleTopNavigation
    }} data-sentry-element="unknown" data-sentry-source-file="SiteNavigation.tsx">
        <SiteHeader onMenuClick={toggleIsLeftSidebarOpen} {...headerProps} data-sentry-element="SiteHeader" data-sentry-source-file="SiteNavigation.tsx" />
        <SiteLeftSidebar onRequestClose={toggleIsLeftSidebarOpen} open={isLeftSidebarOpen} {...leftSidebarProps} data-sentry-element="SiteLeftSidebar" data-sentry-source-file="SiteNavigation.tsx" />
        <SiteTopNavigation isOpen={isTopNavigationOpen} {...topNavigationProps} data-sentry-element="SiteTopNavigation" data-sentry-source-file="SiteNavigation.tsx" />
      </NavigationContext.Provider>
    </Box>;
};