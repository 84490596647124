/* eslint-disable @typescript-eslint/no-shadow */
import styled from 'styled-components';
import { BackgroundProps, FlexboxProps, LayoutProps, SpaceProps, BackgroundColorProps, BorderProps, ColorProps, GridProps, PositionProps, ShadowProps, TypographyProps, background, border, color, compose, flexbox, grid, layout, position, shadow, space, system, typography } from 'styled-system';
type BoxSizingProps = {
  boxSizing?: '-moz-initial' | 'inherit' | 'initial' | 'revert' | 'unset' | 'border-box' | 'context-box';
};
type StyledSystemProps = BackgroundProps & BackgroundColorProps & BorderProps & BoxSizingProps & ColorProps & FlexboxProps & GridProps & LayoutProps & PositionProps & ShadowProps & SpaceProps & TypographyProps;

// TODO: Move other "system" to another package?
const boxSizing = system({
  boxSizing: {
    property: 'boxSizing'
  }
});
const styleProps = compose(background, border, boxSizing, color, flexbox, grid, layout, position, shadow, space, typography);
export type Props = StyledSystemProps;
export const Divider = styled.hr.attrs<Props>(({
  bg = 'lightGrey',
  border = 'none',
  boxSizing = 'border-box',
  height = '1px'
}: Props): Partial<Props> => ({
  bg,
  border,
  boxSizing,
  height
}))<Props>(styleProps);