import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { ListItem } from '../ListItem';
import { Link } from '../../../../../core-components/link';
import { BoxProps } from '../../../../../next-components/box';
type NavigationItemData = {
  url: string;
};
export type NavigationItemProps = BoxProps & {
  data: NavigationItemData;
  linkProps?: BoxProps;
  hoverBackground?: string;
  hoverColor?: string;
  children: ReactNode;
};
const StyledListItem = styled(ListItem)<NavigationItemProps>`
  ${({
  hoverColor
}) => `
    &:hover {
      color: ${hoverColor};
    }
  `}
`;
export const NavigationItem = ({
  data: {
    url
  },
  linkProps = null,
  ...rest
}: NavigationItemProps) => url ? <Link href={url} bg="transparent" color="charcoal" fontFamily="muli" hoverColor="#727272" disabled={!url} {...linkProps}>
        <StyledListItem position="relative" fontSize="inherit" color="inherit" fontWeight="inherit" fontFamily="inherit" {...rest} />
      </Link> : <StyledListItem color="charcoal" fontFamily="muli" {...rest} />;