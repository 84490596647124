'use client';

import React, { useState } from 'react';
import { CopyrightText } from '../../CopyrightText';
import { SocialIconLinks } from '../../SocialIconLinks';
import { AppStoreLink } from '../../AppStoreLink';
import { GooglePlayLink } from '../../GooglePlayLink';
import { Link } from '../../types/types';
import { DownloadAppText } from '../../DownloadAppText';
import { FooterLink } from '../../FooterLink';
import { FooterHelpCenterLink } from './FooterHelpCenterLink';
import { FooterLinksGroup } from './FooterLinksGroup';
import { WagLogoIcon } from '../../../../next-components/icons';
import { Flex } from '../../../../next-components/Flex';
import { Box } from '../../../../core-components/box';
import { Divider } from '../../../../core-components/divider';
import { ConsentConfirmation } from '../../ConsentConfirmation/ConsentConfirmation';
import { ConsentModal } from '../../ConsentModal';
type Props = {
  data: {
    aboutWagLinks: Link[];
    legalLinks: Link[];
    lifestyleLinks: Link[];
    resourcesLinks: Link[];
    securityLinks: Link[];
    topWagCitiesLinks: Link[];
  };
  display: string[];
};
export const SiteFooterDesktop = (props: Props) => {
  const {
    data,
    ...rest
  } = props;
  const {
    aboutWagLinks,
    legalLinks,
    lifestyleLinks,
    resourcesLinks,
    securityLinks,
    topWagCitiesLinks
  } = data;
  const [consentModalIsOpen, setConsentModalIsOpen] = useState(false);
  return <Box bg="backgroundGray" boxSizing="border-box" px="32px" width={1} {...rest} data-sentry-element="Box" data-sentry-component="SiteFooterDesktop" data-sentry-source-file="SiteFooterDesktop.tsx">
      <Box maxWidth="1120px" mx="auto" pb="40px" pt="56px" data-sentry-element="Box" data-sentry-source-file="SiteFooterDesktop.tsx">
        <Flex data-sentry-element="Flex" data-sentry-source-file="SiteFooterDesktop.tsx">
          <Flex flex={4} flexDirection="column" minWidth="184px" mr="16px" data-sentry-element="Flex" data-sentry-source-file="SiteFooterDesktop.tsx">
            <WagLogoIcon mb="24px" name="wagTextLogo" alt="wagTextLogo" width="112px" height="56px" data-sentry-element="WagLogoIcon" data-sentry-source-file="SiteFooterDesktop.tsx" />
            <FooterHelpCenterLink mb="40px" data-sentry-element="FooterHelpCenterLink" data-sentry-source-file="SiteFooterDesktop.tsx" />
            <DownloadAppText data-sentry-element="DownloadAppText" data-sentry-source-file="SiteFooterDesktop.tsx" />
            <AppStoreLink mb="16px" data-sentry-element="AppStoreLink" data-sentry-source-file="SiteFooterDesktop.tsx" />
            <GooglePlayLink mb="32px" data-sentry-element="GooglePlayLink" data-sentry-source-file="SiteFooterDesktop.tsx" />
          </Flex>
          <Flex flex={2} flexDirection="column" mr="16px" data-sentry-element="Flex" data-sentry-source-file="SiteFooterDesktop.tsx">
            <FooterLinksGroup data={{
            links: aboutWagLinks
          }} title="About Wag!" data-sentry-element="FooterLinksGroup" data-sentry-source-file="SiteFooterDesktop.tsx" />
          </Flex>
          <Flex flex={2} flexDirection="column" mr="16px" data-sentry-element="Flex" data-sentry-source-file="SiteFooterDesktop.tsx">
            <FooterLinksGroup data={{
            links: topWagCitiesLinks
          }} title="Top Wag! cities" data-sentry-element="FooterLinksGroup" data-sentry-source-file="SiteFooterDesktop.tsx" />
          </Flex>
          <Flex flex={2} flexDirection="column" mr="16px" data-sentry-element="Flex" data-sentry-source-file="SiteFooterDesktop.tsx">
            <FooterLinksGroup data={{
            links: resourcesLinks
          }} title="Resources" data-sentry-element="FooterLinksGroup" data-sentry-source-file="SiteFooterDesktop.tsx" />
          </Flex>
          <Flex flexDirection="column" flex={2} data-sentry-element="Flex" data-sentry-source-file="SiteFooterDesktop.tsx">
            <FooterLinksGroup data={{
            links: lifestyleLinks
          }} title="Lifestyles" mb="32px" data-sentry-element="FooterLinksGroup" data-sentry-source-file="SiteFooterDesktop.tsx" />
            <FooterLinksGroup data={{
            links: securityLinks
          }} title="Security" data-sentry-element="FooterLinksGroup" data-sentry-source-file="SiteFooterDesktop.tsx" />
          </Flex>
        </Flex>
        <Divider mb="28px" mt="40px" data-sentry-element="Divider" data-sentry-source-file="SiteFooterDesktop.tsx" />
        <Flex mb="40px" data-sentry-element="Flex" data-sentry-source-file="SiteFooterDesktop.tsx">
          {legalLinks.map(({
          url,
          label
        }) => <FooterLink key={label} href={url} mr="16px" style={{
          flex: 2
        }}>
              {label}
            </FooterLink>)}

          <ConsentConfirmation setConsentModalIsOpen={setConsentModalIsOpen} data-sentry-element="ConsentConfirmation" data-sentry-source-file="SiteFooterDesktop.tsx" />

          {consentModalIsOpen && <ConsentModal setConsentModalIsOpen={setConsentModalIsOpen} consentModalIsOpen={consentModalIsOpen} />}
          <Box mr="16px" style={{
          flex: 2
        }} data-sentry-element="Box" data-sentry-source-file="SiteFooterDesktop.tsx" />
          <SocialIconLinks display="flex" minWidth="fit-content" style={{
          flex: 2,
          justifyContent: 'flex-end'
        }} data-sentry-element="SocialIconLinks" data-sentry-source-file="SiteFooterDesktop.tsx" />
        </Flex>
        <CopyrightText mr="16px" textAlign={['left', 'center', null]} style={{
        flex: 4
      }} data-sentry-element="CopyrightText" data-sentry-source-file="SiteFooterDesktop.tsx" />
      </Box>
    </Box>;
};