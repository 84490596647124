import React, { ReactNode } from 'react';
import { Link } from '../../../../core-components/link';
import { Typography, TypographyProps } from '../../../Typography';
export type SidebarLinkProps = {
  href?: string;
  color?: string;
  children?: ReactNode;
} & TypographyProps;
export const SidebarLink = ({
  href,
  children,
  ...rest
}: SidebarLinkProps) => <Typography variant="body2" color="charcoal" display="block" fontSize="16px" fontWeight="400" my="20px" {...rest} data-sentry-element="Typography" data-sentry-component="SidebarLink" data-sentry-source-file="SidebarLink.tsx">
    {href ? <Link href={href} color="inherit">
        {children}
      </Link> : <>
        {children}
      </>}
  </Typography>;