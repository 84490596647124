/* eslint-disable @typescript-eslint/no-shadow */
import React, { ElementType, FunctionComponent } from 'react';
import styled from 'styled-components';
import { compose, system } from 'styled-system';
import { lineClamp, LineClampProps } from '../common/styled-system-adjunct/lineClamp';
import { TextTransformProperty, WhiteSpaceProperty } from '../common/styled-system-adjunct/csstype';
import { Box, BoxProps } from '../next-components/box';

// Place the default props here specific to each variant
const variantMapping = {
  h1: (props: BoxProps) => <Box as="h1" {...props} />,
  h2: (props: BoxProps) => <Box as="h2" {...props} />,
  h3: (props: BoxProps) => <Box as="h3" {...props} />,
  h4: (props: BoxProps) => <Box as="h4" {...props} />,
  h5: (props: BoxProps) => <Box as="h5" {...props} />,
  body1: (props: BoxProps) => <Box as="p" {...props} />,
  body2: (props: BoxProps) => <Box as="span" {...props} />,
  legal: (props: BoxProps) => <Box as="p" {...props} />
};
type Variants = keyof typeof variantMapping;
export type TypographyProps = BoxProps & {
  component?: ElementType | keyof JSX.IntrinsicElements;
  hover?: boolean;
  lineClamp?: LineClampProps['lineClamp'] | (number[] | string | string[]);
  textTransform?: TextTransformProperty | TextTransformProperty[];
  variant?: Variants;
  whiteSpace?: WhiteSpaceProperty | WhiteSpaceProperty[];
};
const defaults = {
  fontSize: (semantic: Variants) => ({
    fontSize: [`mobile-${semantic}`, `tablet-${semantic}`, `desktop-${semantic}`]
  })
};
const BaseTypography = ({
  color = 'medGray',
  variant = 'body1',
  fontFamily = 'muli',
  component,
  ...rest
}: TypographyProps) => {
  const Component = component || variantMapping[variant];
  return <Component color={color} fontFamily={fontFamily} {...defaults.fontSize(variant)} {...rest} data-sentry-element="Component" data-sentry-component="BaseTypography" data-sentry-source-file="Typography.tsx" />;
};
const textTransform = system({
  textTransform: {
    property: 'textTransform'
  }
});
const whiteSpace = system({
  whiteSpace: {
    property: 'whiteSpace'
  }
});
const styleProps = compose(lineClamp, textTransform, whiteSpace);
const StyledTypography = styled(BaseTypography)`
  ${styleProps}
  /* Hover prop is generally used by different consumers to achieve on-hover & cursor effects */
  ${({
  hover
}) => hover && `
    &:hover {
      cursor: pointer;
    }
  `}
  /**
  * These are the properties that we add if we want to enable 'lineClamp'
  * look for <TruncatedTypography /> as the best predate component representation
  */
  ${({
  lineClamp
}) => lineClamp && `
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  `}
` as unknown as FunctionComponent<React.PropsWithChildren<TypographyProps>>;
export { StyledTypography as Typography };