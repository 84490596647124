import React, { ReactNode } from 'react';
import { Link } from '../../../../core-components/link';
import { Typography } from '../../../Typography';
import { Flex } from '../../../../next-components/Flex';
import { Box } from '../../../../next-components/box';
export type SidebarPromotionProps = {
  href: string;
  IconComponent: ReactNode;
  promoText: string;
};
export const SidebarPromotion = ({
  href,
  IconComponent,
  promoText
}: SidebarPromotionProps) => <Link href={href} target="_blank" mt={1} data-sentry-element="Link" data-sentry-component="SidebarPromotion" data-sentry-source-file="SidebarPromotion.tsx">
    <Flex alignItems="center" bg="buttermilk" borderRadius={3} mx={1} p={1} data-sentry-element="Flex" data-sentry-source-file="SidebarPromotion.tsx">
      <Box cursor="pointer" data-sentry-element="Box" data-sentry-source-file="SidebarPromotion.tsx">
        {IconComponent}
      </Box>
      <Typography variant="legal" color="shingleFawn" cursor="pointer" fontFamily="muliBold" m={0} pl={1} data-sentry-element="Typography" data-sentry-source-file="SidebarPromotion.tsx">
        {promoText}
      </Typography>
    </Flex>
  </Link>;