import React, { CSSProperties } from 'react';
import { helpCenterLinkData } from '../../urls';
import { helper } from '../../utils';
import { HelpIcon } from './HelpIcon';
import { Link } from '../../../../core-components/link';
import { Span } from '../../../../core-components/span';
import { BoxProps } from '../../../../next-components/box';
type Props = {
  style?: CSSProperties;
} & BoxProps;
const defaultStyles = {
  alignItems: 'center',
  cursor: 'pointer'
};
export const FooterHelpCenterLink = (props: Props) => {
  const {
    style
  } = props;
  const {
    url: helpCenterLinkUrl
  } = helper.attachQueryStringToRoute(helpCenterLinkData);
  return <Link alignItems="center" color="#01BE8F" cursor="pointer" display="flex" href={helpCenterLinkUrl} {...props} style={{
    ...defaultStyles,
    ...style
  }} data-sentry-element="Link" data-sentry-component="FooterHelpCenterLink" data-sentry-source-file="FooterHelpCenterLink.tsx">
      <Span display="inline-flex" mr="8px" style={{
      cursor: 'pointer'
    }} data-sentry-element="Span" data-sentry-source-file="FooterHelpCenterLink.tsx">
        <HelpIcon color="#01BE8F" fontSize="1.25rem" data-sentry-element="HelpIcon" data-sentry-source-file="FooterHelpCenterLink.tsx" />
      </Span>
      <Span fontFamily="muli" fontSize={['1rem', '1rem', '1.125rem']} style={{
      cursor: 'pointer'
    }} data-sentry-element="Span" data-sentry-source-file="FooterHelpCenterLink.tsx">
        Help Center
      </Span>
    </Link>;
};