'use client';

// @flow
import React, { useState } from 'react';
import { Link } from '../../types/types';
import { AppStoreLink } from '../../AppStoreLink';
import { CopyrightText } from '../../CopyrightText';
import { FooterLink } from '../../FooterLink';
import { SocialIconLinks } from '../../SocialIconLinks';
import { GooglePlayLink } from '../../GooglePlayLink';
import { FooterHelpCenterLink } from './FooterHelpCenterLink';
import { FooterLinksGroup } from './FooterLinksGroup';
import { Flex } from '../../../../next-components/Flex';
import { WagLogoIcon } from '../../../../next-components/icons';
import { Box } from '../../../../core-components/box';
import { Grid } from '../../../../core-components/grid';
import { Divider } from '../../../../core-components/divider';
import { ConsentConfirmation } from '../../ConsentConfirmation/ConsentConfirmation';
import { ConsentModal } from '../../ConsentModal';
type Props = {
  data: {
    aboutWagLinks: Link[];
    legalLinks: Link[];
    lifestyleLinks: Link[];
    resourcesLinks: Link[];
    securityLinks: Link[];
    topWagCitiesLinks: Link[];
  };
  display: string[];
};
export const SiteFooterMobile = ({
  data,
  ...rest
}: Props) => {
  const [consentModalIsOpen, setConsentModalIsOpen] = useState(false);
  const {
    aboutWagLinks,
    legalLinks,
    lifestyleLinks,
    resourcesLinks,
    securityLinks,
    topWagCitiesLinks
  } = data;
  return <Box bg="backgroundGray" boxSizing="border-box" pb="16px" pt="32px" px="16px" width={1} {...rest} data-sentry-element="Box" data-sentry-component="SiteFooterMobile" data-sentry-source-file="SiteFooterMobile.tsx">
      <Flex data-sentry-element="Flex" data-sentry-source-file="SiteFooterMobile.tsx">
        <Flex flexDirection="column" mr="32px" data-sentry-element="Flex" data-sentry-source-file="SiteFooterMobile.tsx">
          <WagLogoIcon mb="32px" name="wagTextLogo" alt="wagTextLogo" width="90px" height="44px" data-sentry-element="WagLogoIcon" data-sentry-source-file="SiteFooterMobile.tsx" />
          <FooterLinksGroup data={{
          links: aboutWagLinks
        }} mb="32px" title="About Wag!" data-sentry-element="FooterLinksGroup" data-sentry-source-file="SiteFooterMobile.tsx" />
          <FooterLinksGroup data={{
          links: topWagCitiesLinks
        }} title="Top Wag! cities" data-sentry-element="FooterLinksGroup" data-sentry-source-file="SiteFooterMobile.tsx" />
        </Flex>
        <Flex flexDirection="column" data-sentry-element="Flex" data-sentry-source-file="SiteFooterMobile.tsx">
          <FooterHelpCenterLink mb="45px" mt="8px" style={{
          alignItems: 'center'
        }} data-sentry-element="FooterHelpCenterLink" data-sentry-source-file="SiteFooterMobile.tsx" />
          <FooterLinksGroup data={{
          links: resourcesLinks
        }} mb="32px" title="Resources" data-sentry-element="FooterLinksGroup" data-sentry-source-file="SiteFooterMobile.tsx" />
          <FooterLinksGroup data={{
          links: lifestyleLinks
        }} mb="32px" title="Lifestyles" data-sentry-element="FooterLinksGroup" data-sentry-source-file="SiteFooterMobile.tsx" />
          <FooterLinksGroup data={{
          links: securityLinks
        }} mb="60px" title="Security" data-sentry-element="FooterLinksGroup" data-sentry-source-file="SiteFooterMobile.tsx" />
          <SocialIconLinks data-sentry-element="SocialIconLinks" data-sentry-source-file="SiteFooterMobile.tsx" />
        </Flex>
      </Flex>
      <Divider mb="40px" mt="32px" data-sentry-element="Divider" data-sentry-source-file="SiteFooterMobile.tsx" />
      <Flex justifyContent="center" mb="32px" data-sentry-element="Flex" data-sentry-source-file="SiteFooterMobile.tsx">
        <AppStoreLink mr="16px" data-sentry-element="AppStoreLink" data-sentry-source-file="SiteFooterMobile.tsx" />
        <GooglePlayLink data-sentry-element="GooglePlayLink" data-sentry-source-file="SiteFooterMobile.tsx" />
      </Flex>
      <Grid mb="100px" gridTemplateColumns="repeat(2, 1fr)" data-sentry-element="Grid" data-sentry-source-file="SiteFooterMobile.tsx">
        {legalLinks.map(({
        url,
        label
      }) => <FooterLink key={label} href={url} style={{
        flex: 2
      }}>
            {label}
          </FooterLink>)}
        <ConsentConfirmation setConsentModalIsOpen={setConsentModalIsOpen} data-sentry-element="ConsentConfirmation" data-sentry-source-file="SiteFooterMobile.tsx" />
        {consentModalIsOpen && <ConsentModal setConsentModalIsOpen={setConsentModalIsOpen} consentModalIsOpen={consentModalIsOpen} />}
      </Grid>
      <CopyrightText data-sentry-element="CopyrightText" data-sentry-source-file="SiteFooterMobile.tsx" />
    </Box>;
};