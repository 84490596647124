import React from 'react';
import { ListItemText } from '../ListItemText';
import { NavigationDropdownList } from '../../../NavigationDropdownList';
import { RouteDefinition } from '../../../types/types';
import { NavigationItem } from './NavigationItem';
import { AnimatedNavigationItemBorder } from './AnimatedNavigationItemBorder';
import { Flex } from '../../../../../next-components/Flex';
type NavigationItemDropdownData = {
  route: RouteDefinition;
  activeRouteKey: string;
};
type Props = {
  data: NavigationItemDropdownData;
  onMouseEnter: (routeKey: string) => void;
  onMouseLeave: () => void;
};
export const NavigationItemDropdown = ({
  data: {
    route,
    activeRouteKey
  },
  onMouseEnter,
  onMouseLeave
}: Props) => {
  const {
    DropdownComponent
  } = route;
  return <Flex height="100%" onMouseEnter={() => onMouseEnter(route.key)} onMouseLeave={onMouseLeave} position={DropdownComponent === NavigationDropdownList ? 'relative' : 'static'} data-sentry-element="Flex" data-sentry-component="NavigationItemDropdown" data-sentry-source-file="NavigationItemDropdown.tsx">
      <NavigationItem key={route.key} data={route} {...route.navigationItemProps} data-sentry-element="NavigationItem" data-sentry-source-file="NavigationItemDropdown.tsx">
        {route.leftIcon}
        <ListItemText mx="8px" position="relative" data-sentry-element="ListItemText" data-sentry-source-file="NavigationItemDropdown.tsx">
          {route.label}

          <AnimatedNavigationItemBorder active={activeRouteKey === route.key} data-sentry-element="AnimatedNavigationItemBorder" data-sentry-source-file="NavigationItemDropdown.tsx" />
        </ListItemText>
        {route.rightIcon}

        {DropdownComponent && <DropdownComponent isActive={activeRouteKey === route.key} items={route.subRoutes} />}
      </NavigationItem>
    </Flex>;
};