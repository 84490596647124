'use client';

import { useEffect, useState, type ReactNode } from 'react';
import { createPortal } from 'react-dom';
type Props = {
  children: ReactNode;
  container?: Element;
};
export const Portal = ({
  children,
  container = document.body
}: Props) => {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);
  if (!isClient) {
    return null;
  }
  return createPortal(children, container);
};