import { attachQueryStringToRoute } from './attachQueryStringToRoute';
export const attachQueryStringToRoutes = (routes = [], queryParams = {}) => routes.map(route => {
  const hasURL = !!route.url;
  const subRoutes = route.subRoutes || [];
  const newSubRoutes = subRoutes.map(subRoute => attachQueryStringToRoute({
    ...subRoute,
    queryParams
  }));
  const {
    url: urlWithQueryString
  } = attachQueryStringToRoute({
    ...route,
    queryParams
  });
  return {
    ...route,
    url: hasURL ? urlWithQueryString : route.url,
    subRoutes: newSubRoutes
  };
});